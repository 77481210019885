import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoArrowRight } from "react-icons/go";
import CorporateForm from "../Contact/CorporateForm";
import offerings1 from '../../Images/offerings1.jpg';

const Corporate = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="min-h-screen bg-[#191614] p-4 sm:p-8">
            <div className="max-w-4xl mx-auto">
                <div className="bg-[#191614] border border-[#b58f49]/20 rounded-lg overflow-hidden">
                    <div className="p-4 sm:p-6 border-b border-[#b58f49]/20">
                        <button
                            onClick={() => navigate('/')}
                            className="text-[#b58f49] hover:text-[#c7a78a] transition-colors mb-4 flex items-center"
                        >
                            <span className="mr-2">←</span> Back to Home
                        </button>
                        <h1 className="text-2xl sm:text-3xl font-bold text-white">
                            Corporate Wellness
                        </h1>
                    </div>
                    <div className="p-4 sm:p-6">
                        <div className="relative w-full h-48 sm:h-64 mb-6 rounded-lg overflow-hidden">
                            <img
                                src={offerings1}
                                alt="Corporate Wellness"
                                className="w-full h-full object-cover"
                            />
                            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#191614]/80" />
                        </div>
                        <div className="flex justify-center">
                            <button
                                className="w-full sm:w-auto flex items-center justify-center space-x-4 bg-[#b58f49] text-black hover:text-white py-3 px-6 sm:px-8 rounded-full font-medium transition-all duration-300 ease-in-out hover:translate-x-1 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-[#b58f49] focus:ring-opacity-50"
                                onClick={handleOpenModal}
                            >
                                <span className="tracking-wider">CONTACT US</span>
                                <GoArrowRight className="h-5 w-5" />
                            </button>
                        </div>
                        <div className="bg-[#191614] rounded-lg shadow-md text-white mt-6 sm:mt-8">
                            <p className="text-gray-300 mb-4 sm:mb-6">
                                Our Corporate Wellness programs are designed to enhance employee well-being and organizational performance by addressing stress, improving focus, and fostering resilience. These programs integrate time-tested practices like Transcendental Meditation (TM) to help employees unlock their full potential.
                            </p>
                            <h2 className="text-xl sm:text-2xl font-semibold text-[#b58f49] mb-4">
                                Key Benefits
                            </h2>
                            <ul className="space-y-3 border border-[#b58f49] p-4 sm:p-6 rounded-xl">
                                <li className="flex items-start">
                                    <span className="font-semibold text-[#b58f49] mr-2">{`Stress Reduction`}</span>
                                    <span className='hidden md:block'><span className='text-[#b58f49]'>:</span> Helps employees manage work pressure and enhance mental clarity.</span>
                                </li>
                                <li className="flex items-start">
                                    <span className="font-semibold text-[#b58f49] mr-2">{`Improved Productivity`}</span>
                                    <span className='hidden md:block'><span className='text-[#b58f49]'>:</span> Boosts efficiency and focus, directly impacting organizational performance.</span>
                                </li>
                                <li className="flex items-start">
                                    <span className="font-semibold text-[#b58f49] mr-2">{`Resilience Building`}</span>
                                    <span className='hidden md:block'><span className='text-[#b58f49]'>:</span> Equips employees to adapt and thrive in challenging work environments.</span>
                                </li>
                                <li className="flex items-start">
                                    <span className="font-semibold text-[#b58f49] mr-2">{`Better Workplace Dynamics`}</span>
                                    <span className='hidden md:block'><span className='text-[#b58f49]'>:</span> Encourages teamwork and harmonious relationships.</span>
                                </li>
                                <li className="flex items-start">
                                    <span className="font-semibold text-[#b58f49] mr-2">{`Employee Retention`}</span>
                                    <span className='hidden md:block'><span className='text-[#b58f49]'>:</span> Contributes to higher job satisfaction and well-being.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <CorporateForm
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                className={`${isModalOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-opacity duration-500 ease-in-out`}
            />
        </div>
    );
};

export default Corporate;
